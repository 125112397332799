export default {
  blogerName: 'GambleWorld',
  socialsList: [
    {
      name: 'kick',
      url: 'https://kick.com/gambleworld',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@gamble_world',
    },
    {
      name: 'facebook',
      url: 'https://www.facebook.com/gambleworldtwitch',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/gamble.world',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/gamble_world',
    },
    {
      name: 'discord',
      url: 'https://discord.com/invite/cEp6Kyx',
    },
    {
      name: 'website',
      url: 'https://gamblebonus24.com/casinos/',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lexcasino.life/c23684a52',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>225% + 600FS</strong><div>für die erste Einzahlung</div>',
    },
    {
      name: '1go',
      url: 'https://1gocasino.life/c5b98b28a',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>für die erste Einzahlung</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs.com/c2cf92056',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>für die erste Einzahlung</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c1269dafb',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>für die erste Einzahlung</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c1ddeb107',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/ceab66298',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c7b86caee',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cf2411066',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c9001e6ac',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c35222fcb',
      gameTitle: 'Doors Of Sol (BGaming)',
    }
  ],
  headerText: 'Verwende den Promo-Code <strong>WORLD50</strong>, um 100 Freispiele in den unten aufgeführten Slots zu erhalten!',
  yourPromocode: 'Dein Promo-Code',
  gameBonus: 'Bonus im Spiel',
  promocode: 'WORLD50',
  casinoBonus1: '<strong>100% + 500 Freispiele</strong><div>für die erste Einzahlung</div>',
  casinoBonus2: '<strong>50 Freispiele</strong><div>für die Anmeldung mit dem Promo-Code</div>',
  copyMessage: 'The promo code has been copied to the clipboard',
  theme: 'dark',
};
